<template>
  <p v-if="done">
    You have been succesfully logged out. Click <a href="/">here</a> to log back
    in.
  </p>
  <p v-else>Logging you out...</p>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    inject: ["api"],
    computed: {
      done() {
        return !this.isAuthenticated;
      },
      ...mapGetters(["isAuthenticated"]),
    },
    async mounted() {
      if (this.isAuthenticated) {
        try {
          // need to be awaited to ensure cookie deletion
          await this.api.logout();
        } finally {
          await this.$logout();
        }
      }
      localStorage.clear();
      sessionStorage.clear();
    },
  };
</script>